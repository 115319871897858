<script lang="ts" setup>
const props = defineProps<{
  toLogin?: boolean
  userId?: string
  buy?: boolean
}>()

const requestCount = ref([1])

const { data } = useAuth()
const config = useRuntimeConfig()

const prices = [{
  count: 10_000,
  monthly_price: 9.99,
  yearly_price: 99.9,
  label: '10K requests',
  short_label: '10K',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/7ecadb7a-954f-4b95-a4c8-47195cdedf12',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/657dda6d-7ee9-4020-b087-9049022c5dfc'
}, {
  count: 100_000,
  monthly_price: 19.99,
  yearly_price: 199.9,
  label: '100K requests',
  short_label: '100K',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/7d45a989-1efb-4cbc-a472-36d1ce895a85',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/eed21f18-72a2-41b3-ae3a-20d601b54739'
}, {
  count: 200_000,
  monthly_price: 29.99,
  yearly_price: 299.9,
  label: '200K requests',
  short_label: '200K',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/1ab897f2-39bd-4786-adaa-8d818f8dba06',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/b22cdc0c-6025-4a1b-9c67-742333667a2a'
}, {
  count: 500_000,
  monthly_price: 49.99,
  yearly_price: 499.9,
  label: '500K requests',
  short_label: '500K',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/aecb70cb-6ee8-4337-b59b-6b8829215992',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/e43256f9-4a8e-4469-bfd0-23848422578a'
}, {
  count: 1_000_000,
  monthly_price: 69.99,
  yearly_price: 699.9,
  label: '1M requests',
  short_label: '1M',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/92078cec-2ab3-4aa5-a2f6-ebcb983bad58',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/a0c9f576-545e-4389-85fd-a3938fd0f616'
}, {
  count: 2_000_000,
  monthly_price: 89.99,
  yearly_price: 899.9,
  label: '2M requests',
  short_label: '2M',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/6357dafa-9f84-4a0f-b095-e38d3b7d495c',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/9d7c4c0b-47ca-4775-9c44-66014e21d3d3'
}, {
  count: 5_000_000,
  monthly_price: 119.99,
  yearly_price: 1199.9,
  label: '5M requests',
  short_label: '5M',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/3d77d96a-f8a0-4a20-825b-c13cf392b3a1',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/eaf61f7d-7097-4938-9b7b-1e6114c7f156'
}, {
  count: 10_000_000,
  monthly_price: 149.99,
  yearly_price: 1499.9,
  label: '10M requests',
  short_label: '10M',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/24178ec5-3e8c-45ed-ae27-4510bb632f13',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/e2c8bb03-3ec8-4966-addd-4f8fec3c5418'
}, {
  count: 10_000_001,
  monthly_price: 199.99,
  yearly_price: 1999.9,
  label: '10M+ requests',
  short_label: '10M+',
  monthly_link: 'https://featrack-io.lemonsqueezy.com/buy/d9116714-2c5d-4f49-9766-2d15ee4af40f',
  yearly_link: 'https://featrack-io.lemonsqueezy.com/buy/7cf46bb8-27a5-4242-a024-495134413030'
}]

const selectedPrice = computed(() => {
  return prices[requestCount.value[0]]
})

const billingCycle = ref<'monthly' | 'yearly'>('monthly')

const features = [
  'Add unlimited apps',
  'Feature tracking, analytics, and insights',
  'Configurable Warning and blocks',
  'Consult usage for any customer',
  'Access to the Featrack.io API',
  'Seamless integration in your apps',
]

const link = computed(() => {
  const billingLink = billingCycle.value === 'monthly' ? selectedPrice.value.monthly_link : selectedPrice.value.yearly_link

  if (props.toLogin) {
    return `/auth/signin`
  }
  if (data.value?.user?.id) {
    return `${billingLink}?checkout[custom][user_id]=${data.value.user.id}`
  }
  return billingLink
})

const currentPrice = computed(() => {
  return billingCycle.value === 'monthly' ? selectedPrice.value.monthly_price : selectedPrice.value.yearly_price
})
</script>

<template>
  <section id="pricing" class="pt-24 pb-20 flex justify-center items-center bg-background">
    <div class="container">
      <h2 class="text-center text-4xl font-extrabold max-w-3xl mx-auto">
        Simple, transparent pricing
      </h2>
      <p class="max-w-2xl mx-auto text-center mt-2">
        Choose the plan that best fits your needs. All plans include a <span class="text-primary font-bold text-xl">14-day</span> free trial.
      </p>

      <Card class="my-6 max-w-2xl mx-auto bg-muted/50">
        <CardHeader>
          <span class="text-sm text-muted-foreground">Up to <span class="text-xl font-bold">{{ selectedPrice.short_label }}</span> requests/month.</span>
          <div class="flex flex-col items-stretch mt-4">
            <Slider
              v-model="requestCount"
              aria-label="Request count"
              name="request-count" :min="0" :max="8" :step="1" class="mb-4"
            />
            <div class="flex justify-between">
              <span v-for="(price, idx) in prices" :key="idx" class="text-xs text-muted-foreground ">
                {{ price.short_label }}
              </span>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div>
            <Tabs v-model="billingCycle" class="w-full mt-8">
              <TabsList class="w-full">
                <TabsTrigger value="monthly" class="flex-grow">
                  <span class="font-bold text-lg">Monthly</span>
                </TabsTrigger>
                <TabsTrigger value="yearly" class="flex-grow">
                  <span class="font-bold text-lg">Yearly <Badge class="text-primary bg-primary/30 hover:text-primary hover:bg-primary/30 text-base">2 Months Free</Badge></span>
                </TabsTrigger>
              </TabsList>
              <TabsContent value="monthly">
                <div class="flex flex-col space-y-6 pt-6">
                  <div class="flex items-center justify-center">
                    <span class="text-3xl text-primary font-black">{{ new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(selectedPrice.monthly_price) }}</span><span>&nbsp;Per Month</span>
                  </div>
                  <div class="mt-6 flex flex-col gap-y-2">
                    <div class="flex items-center gap-2">
                      <iconify-icon icon="lucide:check" class="text-green-500 bg-green-200 rounded-full" /> <span>Send up to {{ selectedPrice.short_label }} requests per month.</span>
                    </div>
                    <div v-for="feature in features" :key="feature" class="flex items-center gap-2">
                      <iconify-icon icon="lucide:check" class="text-green-500 bg-green-200 rounded-full" />
                      <span>{{ feature }}</span>
                    </div>
                  </div>
                </div>
              </TabsContent>
              <TabsContent value="yearly">
                <div class="flex flex-col space-y-6 pt-6">
                  <div class="flex items-center justify-center">
                    <span class="text-3xl text-primary font-black">{{ new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(selectedPrice.yearly_price) }}</span><span>&nbsp;Per Year</span>
                  </div>
                  <div class="mt-6 flex flex-col gap-y-2">
                    <div class="flex items-center gap-2">
                      <iconify-icon icon="lucide:check" class="text-green-500 bg-green-200 rounded-full" /> <span>Send up to {{ selectedPrice.short_label }} requests per month.</span>
                    </div>
                    <div v-for="feature in features" :key="feature" class="flex items-center gap-2">
                      <iconify-icon icon="lucide:check" class="text-green-500 bg-green-200 rounded-full" />
                      <span>{{ feature }}</span>
                    </div>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
            <NuxtLink as-child :to="link" :external="buy">
              <AppButton v-if="!buy" class="mt-6 w-full font-bold" size="lg">
                <span>Try Featrack.io for <u>free {{ config.public.in_beta ? 'while we are in beta' : 'for 14 days' }} </u></span>
              </AppButton>
              <AppButton v-else class="mt-6 w-full font-bold" size="lg">
                <span>Subscribe for <u>{{ new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(currentPrice) }}</u> {{ billingCycle === 'monthly' ? 'per Month' : 'per Year' }}</span>
              </AppButton>
            </NuxtLink>
          </div>
        </CardContent>
      </Card>
    </div>
  </section>
</template>
